<template>
  <div>
    <st-form-title :formTitle="label" class="mt-32" subTitle />
    <div class="flex gap-16 items-center">
      <div
        class="relative flex-1"
        :class="{
          'z-[2]': isSearchLayerOpen
        }"
      >
        <div v-click-outside="onClickOutsideDropdown">
          <input-text
            v-model="searchText"
            :placeholder="
              $t('studio.prj_prod.this_prod.home_product_setting_prod_search_place_holder')
            "
            searchable
            :pattern="REGEX_PRODUCT_NAME"
            :countable="false"
            maxLength="60"
            :disabled="disabledInput"
            @search="onSearch"
            @focus="onFocus"
            @clear="onSearch"
          />
        </div>
        <div
          v-show="isSearchLayerOpen"
          class="absolute top-[calc(100%+.4rem)] left-0 right-0 p-16 border-1 border-solid border-border bg-surface-layer rounded-2xl shadow-[0_.4rem_1rem_0_rgba(0,0,0,.10)]"
          @click.stop
        >
          <div
            v-if="checkBoxData.length === 0"
            class="min-h-56 flex justify-center items-center -mb-16"
          >
            <p v-if="!isLoadingWhenFocus" class="text-xs leading-xs text-center text-placeholder">
              {{ $t('studio.prj_prod.this_prod.home_product_setting_prod_search_nmrf') }}
            </p>
          </div>
          <div v-else v-end-scroll="onLoadMore" class="max-h-[12rem] studio-scrollbar-4">
            <checkbox-group
              v-model="selectedBoxList"
              :options="checkBoxData"
              :optionProps="{
                class: 'min-h-24'
              }"
              :isSingle="isSingle"
              :showTooltip="true"
              class="flex flex-col gap-8"
              size="sm"
              @update:modelValue="onChangeBoxList"
            />
          </div>
          <div class="mt-16">
            <s-button
              variant="outline"
              icon="ic-v2-control-add-line"
              size="sm"
              class="!w-full"
              @click="onCreateNewProduct"
            >
              {{ $t('studio.prj_prod.this_prod.home_product_setting_depth_search_add_btn') }}
            </s-button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="addedProductData.length > 0">
      <m-table class="mt-8">
        <colgroup>
          <col style="width: 10.8rem" />
          <col style="width: auto" />
          <col style="width: 10rem" />
          <col style="width: 6.4rem" />
        </colgroup>
        <thead>
          <tr>
            <st-th
              :thTitle="$t('studio.prj_prod.this_prod.home_product_setting_prod_id_header1')"
            />
            <st-th
              :sortingUse="linkType !== LINKAGE_PRODUCT_TYPE.DEMO"
              :thTitle="$t('studio.prj_prod.this_prod.home_product_setting_prod_name_header2')"
              :sortValue="sortProductNameValue"
              @onSort="onSort"
            />
            <st-th
              :thTitle="$t('studio.prj_prod.this_prod.home_product_setting_prod_status_header3')"
              colspan="2"
            />
          </tr>
        </thead>
        <tbody>
          <st-tr v-for="(item, index) in showProductData" :key="item.productNo">
            <st-td aLeft :tdValue="item.productNo.toString()" />
            <st-td aLeft>
              <a
                :href="`/${groupId}/projects/${projectId}/products/${item.productNo}`"
                target="_blank"
              >{{ item.productName }}</a>
            </st-td>
            <st-td aLeft>
              <span class="flex gap-4">
                {{
                  $t(
                    getProductReleaseStatusLabel(
                      item.planStatus,
                      item.saleStatus,
                      item.verifyStatus,
                      item.displayYn,
                      item.deleteRequestStatus
                    )
                  )
                }}
                <st-tooltip-info :content="$t(getDisplaySanctionText(item.restrictStatus))" />
              </span>
            </st-td>
            <st-td onlyButton>
              <s-button
                variant="outline"
                size="sm"
                :isDisabled="
                  !isProductWritable ||
                    (linkType === LINKAGE_PRODUCT_TYPE.DLC && item.planStatus && item.planStatus !== PLAN_STATUS.NONE)
                "
                @click="onUnlinkProduct(index)"
              >
                {{ $t('studio.prj_prod.this_prod.home_product_setting_list_unlink_btn') }}
              </s-button>
            </st-td>
          </st-tr>
        </tbody>
      </m-table>
      <s-pagination
        v-if="!isSingle && pageCount > 1"
        v-model="currentPage"
        :pageCount="pageCount"
        :displayFirstAndLastButton="false"
        class="!py-32"
        @click="onChangePage"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchInGamePurchase } from '@/apis/in-game.api';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTooltipInfo from '@/components/common/st-tooltip-info.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import StTr from '@/components/common/table/st-tr.vue';
import CreateNewProductDialog from '@/components/product-settings/dialog/create-new-product-dialog.vue';
import ProductSettingConfirmDialog from '@/components/product-settings/product-setting-confirm-dialog.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import InputText from '@/components/validation/input-text.vue';
import { showDialog } from '@/composables/useDialog';
import { SORT_VALUE } from '@/constants/common.const';
import {
  LINKAGE_PRODUCT_TYPE,
  type LinkageProductType,
  PLAN_STATUS,
  SERVER_PRODUCT_TYPE
} from '@/constants/products.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { Confirmation, STOVE_EMAIL } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import type { SearchParams } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { ProductParentChild } from '@/types/product/product-response.type';
import { getDisplaySanctionText, getProductReleaseStatusLabel } from '@/utils/product.util';

const { t } = useI18n();

const PAGE_SIZE = 10;

const props = defineProps<{
  data: ProductParentChild[];
  addedData?: ProductParentChild[];
  label: string;
  linkType: LinkageProductType;
  type: string;
  totalPages: number;
  productNo: string;
  productName: string;
  groupId: string;
  projectId: string;
  isLoadingWhenFocus?: boolean;
  isSingle?: boolean;
  excludeProducts?: ProductParentChild[];
}>();

const emits = defineEmits<{
  search: [params: SearchParams];
  loadMore: [params: SearchParams];
  updateSelected: [v: ProductParentChild[]];
  createNewProduct: [v: ProductParentChild];
  focus: [params: SearchParams];
}>();

const openingDlcIngameWarning = ref(false);

const productStore = useProductStore();
const { isProductWritable, product } = storeToRefs(productStore);

const {
  data,
  linkType,
  type,
  addedData,
  totalPages,
  productNo,
  productName,
  isSingle,
  excludeProducts
} = toRefs(props);

const checkBoxData = computed<FormOption[]>(() => {
  return data.value
    .filter(
      (item: ProductParentChild) =>
        (!excludeProducts.value ||
          excludeProducts.value.every(
            (newItem: ProductParentChild) => newItem.productNo !== item.productNo
          )) &&
        item.productNo !== Number(productNo.value)
    )
    .map((item: ProductParentChild) => ({
      label: `${item.productName} (${item.productNo})`,
      value: item.productNo.toString(),
      isDisabled: isDisabledOption(item.productNo)
    }));
});

const isSearchLayerOpen = ref<boolean>(false);
const searchText = ref<string>('');
const selectedBoxList = ref<string[]>(
  addedData.value?.map((item: ProductParentChild) => item.productNo.toString()) || []
); // just contain id to use with checkbox-group
const isOpeningCreatePopup = ref(false);
const sortProductNameValue = ref(SORT_VALUE.NONE);

const addedProductData = ref<ProductParentChild[]>(
  addedData.value?.map((item: ProductParentChild) => ({
    ...item
  })) || []
);
const sortedProductData = ref<ProductParentChild[]>([...addedProductData.value]);

const searchParams = ref<SearchParams>({
  q: '',
  page: 1,
  size: PAGE_SIZE
});

// table pagination by FE
const isCalledData = ref(false);
const currentPage = ref<number>(1);
const pageCount = computed(() => Math.ceil(addedProductData.value.length / PAGE_SIZE));
const showProductData = computed(() => {
  const start = (currentPage.value - 1) * PAGE_SIZE;
  const end = currentPage.value * PAGE_SIZE;
  return sortedProductData.value.slice(start, end);
});

// watch selectedBoxList change to update selectedProductData
watch(selectedBoxList, async (newValue: string[], oldValue: string[]) => {
  // compare oldValue and newValue to get added or removed product
  const addedProduct = newValue.filter((item: string) => !oldValue.includes(item));
  const removedProduct = oldValue.filter((item: string) => !newValue.includes(item));
  if (linkType.value === LINKAGE_PRODUCT_TYPE.DLC && type.value === SERVER_PRODUCT_TYPE.GAME) {
    const selectedProduct = data.value.find(
      (item: ProductParentChild) => Number(addedProduct[0]) === item.productNo
    );
    const isLinked = await linkIngamePurchaseDLC(selectedProduct?.gameId ?? '');
    if (isLinked) {
      selectedBoxList.value = oldValue;
      return;
    }
  }

  // add or remove product from selectedProductData
  if (addedProduct.length > 0) {
    selectedProductData.value.unshift(
      ...data.value.filter((item: ProductParentChild) => Number(addedProduct[0]) === item.productNo)
    );
  } else if (removedProduct.length > 0) {
    selectedProductData.value = selectedProductData.value.filter(
      (item: ProductParentChild) => Number(removedProduct[0]) !== item.productNo
    );
  }
});

watch(
  () => excludeProducts.value || [],
  (value: ProductParentChild[]) => {
    if (linkType.value === LINKAGE_PRODUCT_TYPE.DEMO) {
      if (value.length > 0 && selectedBoxList.value.length === 1) {
        const found = value.some(
          (newItem: ProductParentChild) => newItem.productNo.toString() === selectedBoxList.value[0]
        );

        if (found) {
          searchText.value = '';
          selectedBoxList.value = [];
        }
      }

      return;
    }

    selectedBoxList.value = selectedBoxList.value.filter(
      (item: string) =>
        !value.some((newItem: ProductParentChild) => newItem.productNo.toString() === item)
    );
  }
);

const selectedProductData = ref<ProductParentChild[]>([]);

const getDisabledSelectedProducts = () => {
  return selectedBoxList.value.filter((selectedProductNo: string) => {
    const selectedProductNoByNumber = Number(selectedProductNo);
    return addedProductData.value.some(
      (addedProduct: ProductParentChild) => addedProduct.productNo === selectedProductNoByNumber
    );
  });
};

const isDisabledOption = (value: number) => {
  return addedProductData.value.some((product: ProductParentChild) => product.productNo === value);
};

const onClickOutsideDropdown = () => {
  if (!isOpeningCreatePopup.value && isSearchLayerOpen.value && !openingDlcIngameWarning.value) {
    isSearchLayerOpen.value = false;
    onAddSelectedProduct();
  }
};

const onChangePage = ({ number }: any) => {
  // pagination emit any
  currentPage.value = number;
};

const onChangeBoxList = (value: string[]) => {
  if (linkType.value === LINKAGE_PRODUCT_TYPE.DEMO) {
    if (value.length > 1) {
      selectedBoxList.value = [value[value.length - 1]];
    }

    onClickOutsideDropdown();
  }
};

const onAddSelectedProduct = async () => {
  const newProducts = data.value.filter(
    (item: ProductParentChild) =>
      selectedBoxList.value.includes(item.productNo.toString()) &&
      !addedProductData.value.some(
        (addedItem: ProductParentChild) => addedItem.productNo === item.productNo
      )
  );
  addedProductData.value.unshift(...newProducts);
  sortedProductData.value = [...addedProductData.value];

  if (isSingle.value) {
    searchText.value = '';
    selectedBoxList.value = [];
  } else {
    selectedBoxList.value = getDisabledSelectedProducts();
  }

  emits('updateSelected', addedProductData.value);
};

const onCreateNewProduct = async () => {
  isOpeningCreatePopup.value = true;
  const result: ProductParentChild = await showDialog({
    component: shallowRef(CreateNewProductDialog),
    props: {
      linkType: linkType.value,
      type: type.value,
      productName: productName.value
    },
    severity: 'info'
  });

  // Need setTimeout to bypass click outside event
  setTimeout(() => {
    isOpeningCreatePopup.value = false;
  });
  emits('createNewProduct', result);
};

const linkIngamePurchaseDLC = async (gameId: string) => {
  if (!gameId) {
    return false;
  }

  const res = await fetchInGamePurchase(gameId);

  if (res?.useYn === Confirmation.YES) {
    openingDlcIngameWarning.value = true;
    await showDialog({
      component: shallowRef(ProductSettingConfirmDialog),
      props: {
        title: t('studio.prj_prod.this_prod.home_product_setting_dlc_link_x_pop_msg1'),
        content: t('studio.prj_prod.this_prod.home_product_setting_dlc_link_x_pop_msg2', {
          storeSupportEmail: STOVE_EMAIL.STORE_SUPPORT
        }),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      },
      severity: 'info'
    });

    openingDlcIngameWarning.value = false;
    return true;
  }

  return false;
};

const getUnlinkTitleByType = (data: LinkageProductType): string => {
  switch (data) {
    case LINKAGE_PRODUCT_TYPE.DEMO:
      return t('studio.prj_prod.this_prod.home_product_setting_demo_list_unlink_pop_msg1');
    case LINKAGE_PRODUCT_TYPE.DLC:
      return t('studio.prj_prod.this_prod.home_product_setting_dlc_list_unlink_pop_msg1');
    case LINKAGE_PRODUCT_TYPE.CONTENTS:
      return t('studio.prj_prod.this_prod.home_product_setting_content_list_unlink_pop_msg1');
    case LINKAGE_PRODUCT_TYPE.UTILITY:
      return t('studio.prj_prod.this_prod.home_product_setting_utility_list_unlink_pop_msg1');
    default:
      return '';
  }
};

const onUnlinkProduct = async (index: number) => {
  const linkedProductName = addedProductData.value[index].productName;
  const accept = await showDialog({
    component: shallowRef(ProductSettingConfirmDialog),
    props: {
      title: getUnlinkTitleByType(linkType.value),
      content: linkedProductName,
      isCancelButtonVisible: true,
      confirmLabel: t('studio.prj_prod.this_prod.home_product_setting_list_unlink_pop_cnf_btn')
    },
    severity: 'info'
  });
  if (!accept) {
    return;
  }

  const selectedProductNo = addedProductData.value[index].productNo.toString();
  selectedBoxList.value = selectedBoxList.value.filter(
    (item: string) => item !== selectedProductNo
  );

  addedProductData.value.splice(index, 1);
  sortedProductData.value = [...addedProductData.value];
  emits('updateSelected', addedProductData.value);

  if (currentPage.value > pageCount.value && currentPage.value !== 1) {
    currentPage.value = pageCount.value;
  }
};

const onFocus = () => {
  if (isSearchLayerOpen.value) {
    return;
  }

  isSearchLayerOpen.value = true;

  if (isSingle.value) {
    searchText.value = '';
  }

  if (!isCalledData.value) {
    isCalledData.value = true;
    searchParams.value = {
      ...searchParams.value,
      q: searchText.value,
      page: 1
    };
    emits('focus', searchParams.value);
  }
};

const disabledInput = computed(() => {
  return (
    (isSingle.value && addedProductData.value.length === 1) ||
    !isProductWritable.value
  );
});

const onSearch = () => {
  if (disabledInput.value) {
    return;
  }

  isSearchLayerOpen.value = true;

  searchParams.value = {
    ...searchParams.value,
    q: searchText.value,
    page: 1
  };
  emits('search', searchParams.value);
};

const onLoadMore = () => {
  if (searchParams.value.page >= totalPages.value) {
    return;
  }
  searchParams.value.page += 1;
  emits('loadMore', searchParams.value);
};

const onSort = (sortValue: number) => {
  sortProductNameValue.value = sortValue;
  if (sortValue === SORT_VALUE.NONE) {
    sortedProductData.value = [...addedProductData.value];
    return;
  }

  sortedProductData.value.sort((a: ProductParentChild, b: ProductParentChild) => {
    if (sortValue === SORT_VALUE.ASC) {
      return a.productName.localeCompare(b.productName, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
    return b.productName.localeCompare(a.productName, undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
};
</script>
