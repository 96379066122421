<template>
  <right-wing>
    <template v-if="showGuides.length > 0">
      <right-wing-item
        v-for="guide in showGuides"
        :key="guide.numberLabel"
        :rightWingTitle="$t(guide.title) ?? ''"
        :numberValue="guide.numberLabel"
        :class="{ 'mt-24': guide.numberLabel !== '01' }"
      >
        <safe-html :html="$t(guide.content)" />
      </right-wing-item>
    </template>
    <template v-if="!isDemo">
      <template v-if="productDetailType !== PRODUCT_DETAIL_TYPE.DLC">
        <p class="mt-16 text-sm text-on-surface-elevation-4 leading-md font-regular">
          {{ $t('studio.prj_prod.this_prod.home_product_setting_guide9') }}
        </p>
        <div class="mt-4">
          <a :href="demoExampleSrc" target="_blank">
            <img :src="demoExampleSrc" alt="" class="w-full block" />
          </a>
        </div>
      </template>
      <template v-if="exampleGuideLabel !== ''">
        <p class="mt-16 text-sm text-on-surface-elevation-4 leading-md font-regular">
          {{ $t(exampleGuideLabel) }}
        </p>
        <div class="mt-4">
          <a :href="lcContentUltityExampleSrc" target="_blank">
            <img :src="lcContentUltityExampleSrc" alt="" class="w-full block" />
          </a>
        </div>
      </template>
    </template>
  </right-wing>
</template>
<script setup lang="ts">
import { computed, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import * as DemoExampleImages from '@/assets/images/product/product-page-demo-application-example';
import * as DlcContentUltityExampleImages from '@/assets/images/product/product-page-dlc-content-ultity-application-example';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import safeHtml from '@/components/common/safe-html.vue';
import { PRODUCT_DETAIL_TYPE, SERVER_PRODUCT_TYPE } from '@/constants/products.const';
import type {
  ProductGuide,
  ProductGuideStep,
  ProductNumberGuideStep
} from '@/types/product/product-model.type';

const { locale } = useI18n();

const props = defineProps<{
  isDemo: boolean;
  productType: string;
  productDetailType: string;
  hasParentProduct?: boolean;
}>();

const { productType, productDetailType, hasParentProduct, isDemo } = toRefs(props);

const demoExampleImages = ref<Record<string, string>>(DemoExampleImages);
const dlcContentUltityExampleImages = ref<Record<string, string>>(DlcContentUltityExampleImages);

const demoExampleSrc = computed(
  () => demoExampleImages.value[`ProductPageDemoExample_${locale.value.replace('-', '_')}`]
);
const lcContentUltityExampleSrc = computed(
  () =>
    dlcContentUltityExampleImages.value[
      `DlcContentUltityAppExample_${locale.value.replace('-', '_')}`
    ]
);

const GUIDES_GAME: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide1',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide1_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_1'
  },
  linkageProducts: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide6',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide6_1'
  }
};

const GUIDES_DEMO_GAME: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide1',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide1_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_1'
  }
};

const GUIDES_DEMO_GAME_CONNECTION: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide1',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide1_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide11',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide11_1'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  }
};

const GUIDES_DLC_GAME_CONNECTION: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide1',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide1_1'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  }
};

const GUIDES_CONTENT: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_2'
  },
  detailType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide4',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide4_1'
  },
  linkageProducts: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide6',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide6_3'
  }
};

const GUIDES_DEMO_CONTENT: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_2'
  },
  detailType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide4',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide4_1'
  }
};

const GUIDES_DEMO_CONTENT_GAME_CONNECTION: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide11',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide11_1'
  },
  detailType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide4',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide4_1'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  }
};

const GUIDES_CONTENT_GAME_CONNECTION: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_2'
  },
  detailType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide4',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide4_1'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  },
  linkageProducts: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide6',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide6_3'
  }
};

const GUIDES_UTILITY: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_3'
  },
  linkageProducts: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide6',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide6_4'
  }
};

const GUIDES_DEMO_UTILITY: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_3'
  }
};

const GUIDES_DEMO_UTILITY_CONNECTION = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide11',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide11_1'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  }
};

const GUIDES_UTILITY_GAME_CONNECTION: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  productType: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide2',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide2_3'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  },
  linkageProducts: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide6',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide6_4'
  }
};

const GUIDES_DLC_UTILITY_CONNECTION: ProductGuide = {
  basicInformation: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide7',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide7_1'
  },
  disconnect: {
    title: 'studio.prj_prod.this_prod.home_product_setting_guide5',
    content: 'studio.prj_prod.this_prod.home_product_setting_guide5_1'
  }
};

const exampleGuideLabel = computed(() => {
  if (
    (!productDetailType.value || productDetailType.value === PRODUCT_DETAIL_TYPE.BASIC) &&
    !hasParentProduct.value
  ) {
    switch (productType.value) {
      case SERVER_PRODUCT_TYPE.GAME:
        return 'studio.prj_prod.this_prod.home_product_setting_guide8';
      case SERVER_PRODUCT_TYPE.UTILITY:
        return 'studio.prj_prod.this_prod.home_product_setting_guide10';
      default:
        return '';
    }
  }
  return '';
});

const processGuides = (productGuide: ProductGuide) => {
  return Object.values(productGuide).map((value: ProductGuideStep, index: number) => ({
    numberLabel: `0${index + 1}`,
    ...value
  }));
};

const showGuides = computed<ProductNumberGuideStep[]>(() => {
  if (productType.value === SERVER_PRODUCT_TYPE.GAME) {
    if (productDetailType.value === PRODUCT_DETAIL_TYPE.DLC) {
      return processGuides(GUIDES_DLC_GAME_CONNECTION);
    }

    if (isDemo.value) {
      if (hasParentProduct.value) {
        return processGuides(GUIDES_DEMO_GAME_CONNECTION);
      }
      return processGuides(GUIDES_DEMO_GAME);
    }

    return processGuides(GUIDES_GAME);
  }

  if (productType.value === SERVER_PRODUCT_TYPE.CONTENTS) {
    if (isDemo.value) {
      if (hasParentProduct.value) {
        return processGuides(GUIDES_DEMO_CONTENT_GAME_CONNECTION);
      }
      return processGuides(GUIDES_DEMO_CONTENT);
    }

    if (hasParentProduct.value) {
      return processGuides(GUIDES_CONTENT_GAME_CONNECTION);
    }
    return processGuides(GUIDES_CONTENT);
  }

  if (productType.value === SERVER_PRODUCT_TYPE.UTILITY) {
    if (isDemo.value) {
      if (hasParentProduct.value) {
        return processGuides(GUIDES_DEMO_UTILITY_CONNECTION);
      }
      return processGuides(GUIDES_DEMO_UTILITY);
    }

    if (hasParentProduct.value) {
      if (productDetailType.value === PRODUCT_DETAIL_TYPE.DLC) {
        return processGuides(GUIDES_DLC_UTILITY_CONNECTION);
      }
      return processGuides(GUIDES_UTILITY_GAME_CONNECTION);
    }
    return processGuides(GUIDES_UTILITY);
  }

  return [];
});
</script>
