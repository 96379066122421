<!--SkyBoxDateComponent-->
<template>
  <div class="relative rounded-3xl py-16 px-20 bg-neutral-variant-2">
    <div class="flex gap-8">
      <p class="text-md font-bold leading-lg text-on-surface-elevation-2">{{ $t(topTitle) }}</p>
      <span class="text-md font-medium leading-lg text-brand-primary">{{
        statusLabel === '-' ? '' : $t(statusLabel)
      }}</span>
    </div>
    <dl class="mt-12 flex gap-16 items-start">
      <div class="min-w-100 flex flex-col gap-4 shrink-0">
        <dt class="text-sm font-medium leading-md text-on-surface-elevation-4 whitespace-nowrap">
          {{ $t('studio.prj_prod.this_prod.home_product_setting_higher_game_no') }}
        </dt>
        <dd class="text-md text-on-surface-elevation-2 font-medium leading-lg">
          {{ data.productNo }}
        </dd>
      </div>
      <div class="flex flex-col gap-4 flex-1">
        <dt class="text-sm font-medium leading-md text-on-surface-elevation-4 whitespace-nowrap">
          {{ $t('studio.prj_prod.this_prod.home_product_setting_higher_game_name') }}
        </dt>
        <dd class="text-md text-on-surface-elevation-2 font-medium leading-lg break-all">
          {{ data.productName }}
        </dd>
      </div>
    </dl>
    <a
      href=""
      class="absolute inset-0"
      @click.prevent="
        redirectTo(PRODUCT_HOME_PAGE_URL, { productId: data.productNo, open: { target: '_blank' } })
      "
    ></a>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps, toRefs } from 'vue';

import { SERVER_PRODUCT_TYPE } from '@/constants/products.const';
import { PRODUCT_HOME_PAGE_URL } from '@/constants/url.const';
import type { ProductParentChild } from '@/types/product/product-response.type';
import { redirectTo } from '@/utils/common.util';
import { getProductReleaseStatusLabel } from '@/utils/product.util';

const props = defineProps<{
  data: ProductParentChild;
}>();

const { data } = toRefs(props);

const topTitle = computed<string>(() => {
  switch (data.value.productType) {
    case SERVER_PRODUCT_TYPE.UTILITY:
      return 'studio.prj_prod.this_prod.home_product_setting_linked_utility';
    case SERVER_PRODUCT_TYPE.CONTENTS:
      return 'studio.prj_prod.this_prod.home_product_setting_linked_content';
    case SERVER_PRODUCT_TYPE.GAME:
    default:
      return 'studio.prj_prod.this_prod.home_product_setting_higher_game';
  }
});
const statusLabel = computed<string>(() =>
  getProductReleaseStatusLabel(
    data.value.planStatus,
    data.value.saleStatus,
    data.value.verifyStatus,
    data.value.displayYn,
    data.value.deleteRequestStatus
  )
);
</script>
