<template>
  <div class="flex gap-24">
    <div class="flex-1">
      <!-- Start Section 1 -->
      <st-box>
        <!-- Basic information -->
        <template v-if="isRatingReview">
          <div class="rounded-2xl bg-[#FFE7E7] py-8 px-16 flex gap-4 items-start">
            <s-icon icon="ic-v2-state-info-circle-line" size="xl" class="mt-[.3rem]" />
            <p class="text-sm leading-md font-medium text-on-surface-elevation-2">
              {{
                $t(
                  'studio.prj_prod.this_prod.home_product_setting_rating_under_review_edit_x_guide'
                )
              }}
            </p>
          </div>
          <div class="mt-20 flex flex-col gap-20">
            <product-info-item
              :titleValue="
                $t(
                  'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_prod_name'
                )
              "
              :dataValue="processedProductInfo?.defaultBasicInformation?.productName || ''"
            />
            <product-info-item
              :titleValue="
                $t(
                  'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_creator_name'
                )
              "
              :dataValue="processedProductInfo?.defaultBasicInformation?.developer || ''"
            />
            <product-info-item
              :titleValue="
                $t(
                  'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_distrib_co_name'
                )
              "
              :dataValue="processedProductInfo?.defaultBasicInformation?.publisher || ''"
            />
            <product-info-item
              :titleValue="
                $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_genre')
              "
              :dataValue="genreText"
            />
          </div>
        </template>
        <template v-else>
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_basic_title')"
            languageUse
            :referenceLanguage="$t(language)"
            required
            @onLanguageClick="onOpenMultilingualSettings"
          />
          <safe-html
            tag="p"
            class="text-xs leading-xs text-placeholder font-regular mt-4"
            :html="$t('studio.prj_prod.this_prod.home_product_setting_basic_msg1')"
          />
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_basic_msg2')"
            subTitle
            class="mt-8"
          />
          <input-text
            :placeholder="
              $t('studio.prj_prod.this_prod.home_product_setting_basic_msg2_place_holder')
            "
            :disabled="!isProductWritable"
            maxLength="50"
            name="basicInformationDefault.productName"
            :rules="{
              regex: {
                regex: REGEX_PRODUCT_NAME,
                message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
              },
              required: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg2_required_n'),
              max_length: 50
            }"
            @blur="onProductNameChanged"
          />

          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_basic_msg3')"
            subTitle
            class="mt-16"
          />
          <input-text
            :placeholder="
              $t('studio.prj_prod.this_prod.home_product_setting_basic_msg3_place_holder')
            "
            :disabled="!isProductWritable"
            maxLength="50"
            name="basicInformationDefault.developer"
            :rules="{
              required: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg3_required_n'),
              max_length: 50
            }"
          />
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_basic_msg4')"
            subTitle
            class="mt-16"
          />
          <input-text
            :placeholder="
              $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_place_holder')
            "
            :disabled="!isProductWritable"
            maxLength="50"
            name="basicInformationDefault.publisher"
            :rules="{
              required: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_required_n'),
              max_length: 50
            }"
          />
          <template v-if="processedProductInfo.exposedFields.genre">
            <st-form-title
              :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_category')"
              required
              :count="true"
              :currentCount="selectedGenres.length"
              :maxCount="MAX_GENRE_COUNT"
              class="mt-32"
            />
            <div class="mt-8 flex flex-wrap gap-x-8 gap-y-12">
              <selectable-tag-list
                name="genreTagNos"
                :tagList="genreOptions"
                :hasLimit="true"
                :maxTag="MAX_GENRE_COUNT"
                :disabled="!isProductWritable"
              />
              <p
                v-if="selectedGenres.length === 0 && isGenreTouched"
                class="flex items-start gap-[.2rem] mt-[.4rem] text-error text-xs leading-xs font-regular"
              >
                <s-icon
                  icon="ic-v2-state-warning-circle-fill"
                  size="xl"
                  class="flex h-16 mt-[.1rem]"
                />
                {{ $t('studio.prj_prod.this_prod.home_product_setting_category_val_msg') }}
              </p>
            </div>
            <template v-if="selectedGenres?.length > 0">
              <div class="mt-20 border-t-1 border-solid border-border">
                <st-form-title
                  required
                  :formTitle="
                    $t('studio.prj_prod.this_prod.home_product_setting_representative_category')
                  "
                  class="mt-16"
                />
              </div>
              <div class="relative max-w-[24rem]">
                <s-dropdown
                  :key="genreOptionKey"
                  v-model="defaultGenreOption"
                  size="sm"
                  variant="line"
                  :distance="0"
                  :offset="[0, 0]"
                  placement="bottom-start"
                  class="w-full"
                  :closeOnClick="true"
                  :isDisabled="!isProductWritable"
                >
                  <s-dropdown-button class="w-full">
                    {{ defaultGenreOption?.label }}
                  </s-dropdown-button>
                  <template #dropdownItems>
                    <s-dropdown-item
                      v-for="genre in selectedGenres"
                      :key="genre?.value"
                      :value="{ value: genre?.value, label: genre?.label }"
                    >
                      <span class="truncate">{{ genre?.label }}</span>
                    </s-dropdown-item>
                  </template>
                </s-dropdown>
              </div>
            </template>
          </template>
        </template>
      </st-box>
      <!-- End Section 1 -->
      <!-- Start Section 2 -->
      <st-box class="mt-24">
        <!-- Product type -->
        <template v-if="processedProductInfo.exposedFields.productType">
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_prod_type')"
            required
          />
          <p class="text-xs leading-xs text-placeholder font-regular mt-4">
            {{ $t('studio.prj_prod.this_prod.home_product_setting_prod_type_msg1') }}
          </p>
          <s-radio-group
            v-model="productType"
            class="flex flex-wrap gap-x-24 mt-12"
            name="productType"
            :isDisabled="disabledProductTypeOption"
          >
            <s-radio
              id="productTypeGame"
              :value="processedProductInfo.productTypeOptions[0]"
              name="radio"
              size="sm"
              align="middle"
              class="capitalize"
            >
              {{ getProductTypeText(processedProductInfo.productTypeOptions[0]) }}
            </s-radio>
            <s-radio
              id="productTypeDemo"
              :value="processedProductInfo.productTypeOptions[1]"
              name="radio"
              size="sm"
              align="middle"
              class="capitalize"
            >
              {{ getProductTypeText(processedProductInfo.productTypeOptions[1]) }}
            </s-radio>
          </s-radio-group>
          <div v-if="productInfo?.productType === SERVER_PRODUCT_TYPE.CONTENTS">
            <st-form-title
              :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_content_type')"
              required
              class="mt-24"
            />
            <radio-group
              v-model="detailType"
              name="detailType"
              :disabled="!isProductWritable"
              :options="DETAIL_TYPE_OPTIONS"
              class="flex gap-x-24 gap-y-8 flex-wrap py-8"
              :rules="{
                required: $t('studio.prj_prod.this_prod.home_product_setting_content_type_val_msg')
              }"
              :optionProps="{
                isDisabled: productInfo?.planStatus === PLAN_STATUS.READY,
                size: 'sm',
                align: 'middle'
              }"
            />
          </div>
        </template>
        <template v-if="processedProductInfo.exposedFields.supportedFeatures">
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_prod_supported_type')"
            class="mt-32"
          />
          <div class="mt-12">
            <s-checkbox
              id="support-online"
              v-model="supportOption.onlineYn"
              :isDisabled="!isProductWritable"
              size="sm"
              align="middle"
            >
              {{ $t('studio.prj_prod.this_prod.home_product_setting_prod_supported_type_online') }}
            </s-checkbox>
            <s-checkbox
              id="support-vr"
              v-model="supportOption.vrYn"
              :isDisabled="!isProductWritable"
              size="sm"
              align="middle"
              class="mt-12"
            >
              {{ $t('studio.prj_prod.this_prod.home_product_setting_prod_supported_type_vr') }}
            </s-checkbox>
          </div>
        </template>
        <div v-if="processedProductInfo.exposedFields.topProduct" class="mt-24">
          <sky-box-date :data="productInfo.parentProduct!" />
        </div>
        <linkage-products
          v-if="showLinkageProducts"
          :linkageProducts="linkageProducts"
          :groupId="groupId"
          :projectId="projectId"
          :serverProductType="processedProductInfo.serverProductType"
          :productNo="productNo"
          :productName="productName"
          :parentProductType="productInfo?.parentProduct?.productType"
          :exposedLinkedProducts="processedProductInfo.exposedFields.linking"
          @updateLinkageProducts="updateLinkageProducts"
        />
      </st-box>
      <!-- End Section 2 -->
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="handleSave">
          {{
            $t(
              isLiveApply
                ? 'studio.common.live_apply_popup_title'
                : 'studio.common.popup_case_save_btn'
            )
          }}
        </s-button>
      </div>
    </div>
    <guides
      :isDemo="productType === PRODUCT_TYPE.DEMO"
      :productType="productInfo?.productType!"
      :productDetailType="productInfo?.productDetailType!"
      :hasParentProduct="!!productInfo?.parentProduct"
    />
  </div>
</template>

<script setup lang="ts">
import { cloneDeep, isEqual } from 'lodash';
import { reloadNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { updateProductApi } from '@/apis/products.api';
import { checkProductInProjectNameApi } from '@/apis/project-product.api';
import { fetchRatingProductLaunchInfoApi } from '@/apis/rating.api';
import SafeHtml from '@/components/common/safe-html.vue';
import SelectableTagList from '@/components/common/selectable-tag-list.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import BasicInfoMultilingual from '@/components/product-settings/basic-info-multilingual.vue';
import ApplyLiveDialog from '@/components/product-settings/dialog/apply-live-dialog.vue';
import Guides from '@/components/product-settings/guides.vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LinkageProducts from '@/components/product-settings/linkage-products.vue';
import ProductInfoItem from '@/components/product-settings/product-info-item.vue';
import SkyBoxDate from '@/components/product-settings/sky-box-date.vue';
import RatingSurveyDialog from '@/components/rating/rating-survey-dialog.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { useProduct } from '@/composables/useProduct';
import { TRANSLATE_LANGUAGES_SETTINGS } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import {
  DETAIL_TYPE_OPTIONS,
  PLAN_STATUS,
  PRODUCT_DETAIL_TYPE,
  PRODUCT_TYPE,
  SERVER_PRODUCT_TYPE
} from '@/constants/products.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import { RatingPageMode, SurveyDialogType } from '@/enums/rating.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { CommonOption, ErrorResponse, LanguageModel } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { MemberResponseModel } from '@/types/member/member-model.type';
import type {
  Genre,
  LinkageProducts as ILinkageProducts,
  ProcessedProductInfo,
  ProductLanguage,
  ProductSettingInfo
} from '@/types/product/product-model.type';
import type { ProductSettingRequest } from '@/types/product/product-request.type';
import type { ProductParentChild, ProductResponse } from '@/types/product/product-response.type';
import type { LangValuePair, RatingProductResponse } from '@/types/rating/rating.response.type';
import type { RevisionProductSettingChanged } from '@/types/rating/rating.type';
import { redirectTo } from '@/utils/common.util';
import { isNotTranslated } from '@/utils/product.util';
import { isSelfReview } from '@/utils/rating.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  productInfo: ProductResponse;
  processedProductInfo: ProcessedProductInfo;
  defaultGenreOption?: CommonOption;
  genreOptions: FormOption[];
  userInfo?: MemberResponseModel;
  defaultLangCode: string;
  groupId: string;
  projectId: string;
  productNo: string;
  originalProductSetting: ProductSettingRequest;
  isRatingReview: boolean;
  ratingsProduct?: RatingProductResponse;
}>();

const GO_LIVE_PLAN_STATUS: string[] = [
  PLAN_STATUS.PAGE_OPEN,
  PLAN_STATUS.PRE_PURCHASE,
  PLAN_STATUS.RELEASE
];

const { checkProductPermission } = useApp();

const productStore = useProductStore();
const appStore = useAppStore();

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

const MAX_GENRE_COUNT = 3;

const { t, locale } = useI18n();

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);
const { isProductWritable } = storeToRefs(productStore);

const { handleSubmit, isFieldValid, validateField, setValues, setErrors } = useForm({
  initialValues: {
    basicInformationDefault: {
      productName: '',
      developer: '',
      publisher: ''
    },
    detailType: '',
    genreTagNos: [] as string[]
  }
});

const isHaveBasicInformationChange = ref(false);
const revisionProductSettingChanged = ref<RevisionProductSettingChanged>({
  isChangedProductName: false,
  isChangedDeveloper: false,
  isChangedGenre: false,
  originProductNameKo: '',
  originProductNameEn: '',
  originDeveloper: '',
  originDefaultGenreTag: undefined,
  originGenreTagNos: undefined,
  productNameKo: '',
  productNameEn: '',
  developer: '',
  defaultGenreTag: undefined,
  defaultGenreTagName: undefined,
  genreTagNos: undefined,
  infos: []
});
const isGenreTouched = ref(false);
const duplicateProductError = ref(false);
const isCheckingProductName = ref(false);
const isClickedSubmit = ref(false);

const genreText = computed(() => {
  if (!props.productInfo?.genres) {
    return '';
  }

  const sortedGenres = [...props.productInfo.genres].sort((a: Genre, b: Genre) => {
    if (a.defaultGenreYn === Confirmation.YES && b.defaultGenreYn !== Confirmation.YES) {
      return -1;
    }

    if (a.defaultGenreYn !== Confirmation.YES && b.defaultGenreYn === Confirmation.YES) {
      return 1;
    }

    return 0;
  });

  const genreTexts = sortedGenres.map((genre: Genre) => {
    if (genre.defaultGenreYn === Confirmation.YES) {
      return `${genre.tagName}(${t(
        'studio.product_setting.under_review_info_genre_representative'
      )})`;
    }
    return genre.tagName;
  });

  return genreTexts.join(', ');
});

// const progressChangeType = async (productDetailType: string) => {
//   const changeProductTypeResult = await showDialog({
//     component: shallowRef(ChangeProductTypeDialog),
//     props: {
//       basicType: props.processedProductInfo.productTypeOptions[0]
//     },
//     severity: 'info'
//   });

//   if (changeProductTypeResult) {
//     const res = await updateProductApi(
//       Number(props.productNo),
//       {
//         ...props.originalProductSetting,
//         productDetailType
//       }
//     );

//     if (res) {
//       await showConfirm({
//         content: t('studio.prj_prod.this_prod.home_product_setting_post_release_type_change_complete_popup'),
//         confirmLabel: t('studio.common.popup_case_cf_btn'),
//         isCancelButtonVisible: false,
//         confirmClasses: '!max-w-full !w-full'
//       });

//       redirectTo(PRODUCT_HOME_PAGE_URL);
//       showConfirm({
//         content: [t('studio.prj_prod.post_release.demo_to_game.popup_msg1'),
//           t('studio.prj_prod.post_release.demo_to_game.popup_msg2'),
//           t('studio.prj_prod.post_release.demo_to_game.popup_msg3')
//         ],
//         confirmLabel: t('studio.common.popup_case_cf_btn'),
//         isCancelButtonVisible: false
//       });
//     } else {
//       showAlert({
//         content: t(COMMON_ERROR_MESSAGE_KEY),
//         severity: 'info',
//         confirmLabel: t('studio.common.popup_case_cf_btn'),
//         confirmClasses: '!w-full !max-w-full'
//       });
//     }
//   }
// };

const onProductNameChanged = async () => {
  try {
    isCheckingProductName.value = true;
    duplicateProductError.value = false;
    const trimmedProductName = productName.value.trim();
    if (trimmedProductName === props.processedProductInfo.defaultBasicInformation.productName) {
      isCheckingProductName.value = false;
      return;
    }

    await checkProductInProjectNameApi({
      groupId: props.groupId,
      productName: trimmedProductName,
      languageCd: 'ALL'
    });
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
      duplicateProductError.value = true;
      const errorMessage = generateErrorMsg(
        'studio.prj_prod.this_prod.home_product_setting_val_duplication_msg',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ 'basicInformationDefault.productName': errorMessage });
    }
  }
  if (isClickedSubmit.value) {
    progressSave();
  }
  isCheckingProductName.value = false;
};

const linkageProducts = ref<ILinkageProducts>({
  demo: [],
  dlc: [],
  content: [],
  utility: []
});

const defaultGenreOption = ref<{ label: string; value: string } | undefined>(
  props.defaultGenreOption
);

const genreOptionKey = ref(0);

watch(
  () => props.defaultGenreOption ?? {},
  () => {
    defaultGenreOption.value = props.defaultGenreOption;
  }
);

const detailType = useFieldValue<string>('detailType');
const genreTagNos = useFieldValue<string[]>('genreTagNos');
const productType = ref<string>();
const supportOption = ref<{ onlineYn: boolean; vrYn: boolean }>({
  onlineYn: false,
  vrYn: false
});

const isLiveApply =
  props.productInfo?.planStatus && GO_LIVE_PLAN_STATUS.includes(props.productInfo.planStatus);

watch(genreTagNos, (newValue: string[], oldValue: string[]) => {
  if (oldValue.length === 0 && newValue.length === 1) {
    defaultGenreOption.value = props.genreOptions.find(
      (tag: FormOption) => tag.value === newValue[0]
    );
    isGenreTouched.value = true;
  } else {
    // get removed genre
    const removedGenre = oldValue.filter((tagNo: string) => !newValue.includes(tagNo));
    if (removedGenre[0] === defaultGenreOption.value?.value) {
      if (newValue.length > 0) {
        defaultGenreOption.value = props.genreOptions.find(
          (tag: FormOption) => tag.value === newValue[0]
        );
      } else {
        defaultGenreOption.value = undefined;
      }
    }

    genreOptionKey.value = new Date().getTime();
  }
});

const selectedGenres = computed(() => {
  return genreTagNos.value.map((tagNo: string) => {
    return props.genreOptions.find((tag: FormOption) => tag.value === tagNo);
  });
});

const linkedProduct = computed(() => {
  return (
    (linkageProducts.value.demo && linkageProducts.value.demo.length > 0) ||
    (linkageProducts.value.dlc && linkageProducts.value.dlc.length > 0) ||
    (linkageProducts.value.content && linkageProducts.value.content.length > 0) ||
    (linkageProducts.value.utility && linkageProducts.value.utility.length > 0)
  );
});

const disabledProductTypeOption = computed(() => {
  return props.processedProductInfo.disabledProductType ||
          linkedProduct.value ||
          !isProductWritable.value ||
          (props.productInfo.planStatus === PLAN_STATUS.RELEASE && productType.value === PRODUCT_TYPE.DEMO);
});

const languages = ref<ProductLanguage[]>([]);
const originalLanguages = ref<ProductLanguage[]>([]);

const productName = useFieldValue<string>('basicInformationDefault.productName');
const developer = useFieldValue<string>('basicInformationDefault.developer');
const publisher = useFieldValue<string>('basicInformationDefault.publisher');

const isClickedMultilingualSettings = ref(false);
const isSubmitted = ref(false);

const showLinkageProducts = computed(() => {
  return (
    productType.value !== PRODUCT_TYPE.DEMO &&
    Object.values(props.processedProductInfo.exposedFields.linking).some((value: boolean) => value)
  );
});

const language = computed(() => {
  return (
    TRANSLATE_LANGUAGES_SETTINGS.find(
      (lang: LanguageModel) => lang.langCode === selectedGroupInfo.value?.languageCd
    )?.langTitle || ''
  );
});

const triggerValidateBasicInformation = async () => {
  const reqValue = []; // `${t('studio.ai_translation.title2_product')}, ${t('studio.ai_translation.title3_creator')}, ${t('studio.ai_translation.title4_distributor')}`;
  const productNameError = await validateField('basicInformationDefault.productName');
  const developerError = await validateField('basicInformationDefault.developer');
  const publisherError = await validateField('basicInformationDefault.publisher');

  if (!productNameError.valid) {
    reqValue.push(t('studio.ai_translation.title2_product'));
  }

  if (!developerError.valid) {
    reqValue.push(t('studio.ai_translation.title3_creator'));
  }

  if (!publisherError.valid) {
    reqValue.push(t('studio.ai_translation.title4_distributor'));
  }

  return reqValue.join(', ');
};

const onOpenMultilingualSettings = async () => {
  isClickedMultilingualSettings.value = true;
  if (isProductWritable.value) {
    const reqValue = await triggerValidateBasicInformation();
    if (!isFieldValid('basicInformationDefault')) {
      showAlert({
        severity: 'info',
        content: t('studio.common.popup_case_lang_setting_input_required', { reqValue }),
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!max-w-full !w-full'
      });
      return;
    }
  }

  const result = await showDialog({
    component: shallowRef(BasicInfoMultilingual),
    props: {
      groupId: props.groupId,
      languages: languages.value
    },
    severity: 'info'
  });
  if (!result) {
    return;
  }
  languages.value = cloneDeep(result) as ProductLanguage[];
};

// check item only change default language
const isChangeDefaultLanguageOnly = computed(() => {
  const defaultLanguage = languages.value.find((lang: ProductLanguage) => lang.default);
  const originalDefaultLanguage = originalLanguages.value.find(
    (lang: ProductLanguage) => lang.default
  );
  if (!defaultLanguage || !originalDefaultLanguage) {
    return false;
  }
  return !isEqual(defaultLanguage, originalDefaultLanguage);
});

// check item not change setting languages
const isNotChangeSettingLanguages = computed(() => {
  const settingLanguages = languages.value.filter((lang: ProductLanguage) => !lang.default);
  const originalSettingLanguages = originalLanguages.value.filter(
    (lang: ProductLanguage) => !lang.default
  );
  if (settingLanguages.length !== originalSettingLanguages.length) {
    return false;
  }
  return settingLanguages.every((lang: ProductLanguage, index: number) =>
    isEqual(lang, originalSettingLanguages[index])
  );
});

const updateRevisionProductSettingChanged = () => {
  revisionProductSettingChanged.value = {
    isChangedProductName: false,
    isChangedDeveloper: false,
    isChangedGenre: false,
    productNameKo: '',
    productNameEn: '',
    developer: '',
    defaultGenreTag: undefined,
    defaultGenreTagName: undefined,
    genreTagNos: undefined,
    infos: []
  };

  const krRatingProductName = props.processedProductInfo.infos.find(
    (lang: ProductLanguage) => lang.langCode === LanguageCode.Ko
  )?.productName;
  const enRatingProductName = props.processedProductInfo.infos.find(
    (lang: ProductLanguage) => lang.langCode === LanguageCode.En
  )?.productName;
  const krRatingDeveloper = props.processedProductInfo.infos.find(
    (lang: ProductLanguage) => lang.langCode === LanguageCode.Ko
  )?.developer;

  const koProductName = languages.value.find(
    (lang: ProductLanguage) => lang.langCode === LanguageCode.Ko
  )?.productName;
  const enProductName = languages.value.find(
    (lang: ProductLanguage) => lang.langCode === LanguageCode.En
  )?.productName;
  const krDeveloper = languages.value.find(
    (lang: ProductLanguage) => lang.langCode === LanguageCode.Ko
  )?.developer;

  if (krRatingProductName !== koProductName) {
    revisionProductSettingChanged.value.productNameKo = koProductName ?? '';
    revisionProductSettingChanged.value.originProductNameKo = krRatingProductName ?? '';
  }

  if (enRatingProductName !== enProductName) {
    revisionProductSettingChanged.value.productNameEn = enProductName ?? '';
    revisionProductSettingChanged.value.originProductNameEn = enRatingProductName ?? '';
  }

  if (krRatingProductName !== koProductName || enRatingProductName !== enProductName) {
    revisionProductSettingChanged.value.isChangedProductName = true;
  }

  if (krRatingDeveloper !== krDeveloper) {
    revisionProductSettingChanged.value.isChangedDeveloper = true;
    revisionProductSettingChanged.value.developer = krDeveloper ?? '';
    revisionProductSettingChanged.value.originDeveloper = krRatingDeveloper ?? '';
  }

  if (
    defaultGenreOption.value?.value !== props.originalProductSetting.defaultGenreTagNo?.toString()
  ) {
    revisionProductSettingChanged.value.isChangedGenre = true;

    revisionProductSettingChanged.value.defaultGenreTag = defaultGenreOption.value?.value
      ? Number(defaultGenreOption.value?.value)
      : undefined;
    revisionProductSettingChanged.value.defaultGenreTagName = defaultGenreOption.value?.label ?? '';
    revisionProductSettingChanged.value.genreTagNos = genreTagNos.value.map((item: string) =>
      Number(item)
    );
    revisionProductSettingChanged.value.originDefaultGenreTag =
      props.originalProductSetting.defaultGenreTagNo;
    revisionProductSettingChanged.value.originGenreTagNos =
      props.originalProductSetting.genreTagNos;
  }

  revisionProductSettingChanged.value.infos = languages.value.map((lang: ProductLanguage) => {
    return {
      languageCd: lang.langCode,
      productName: lang.productName,
      developer: lang.developer,
      publisher: lang.publisher
    };
  });
};

const checkChangedRatingInformation = async () => {
  const launchInfo = await fetchRatingProductLaunchInfoApi(props.productNo);
  if (launchInfo) {
    const krRatingProductName = launchInfo.productNames.find(
      (item: LangValuePair) => item.lang === LanguageCode.Ko
    )?.value;
    const enRatingProductName = launchInfo.productNames.find(
      (item: LangValuePair) => item.lang === LanguageCode.En
    )?.value;
    const krRatingDeveloper = launchInfo.developers.find(
      (item: LangValuePair) => item.lang === LanguageCode.En
    )?.value;
    const ratingDefaultGenreTagNo = launchInfo.genreTagNo.toString();

    const krProductName = languages.value.find(
      (lang: ProductLanguage) => lang.langCode === LanguageCode.Ko
    )?.productName;
    const enProductName = languages.value.find(
      (lang: ProductLanguage) => lang.langCode === LanguageCode.En
    )?.productName;
    const krDeveloper = languages.value.find(
      (lang: ProductLanguage) => lang.langCode === LanguageCode.Ko
    )?.developer;
    const defaultGenreTagNo = defaultGenreOption.value?.value;

    if (
      krRatingProductName !== krProductName ||
      enRatingProductName !== enProductName ||
      krRatingDeveloper !== krDeveloper ||
      ratingDefaultGenreTagNo !== defaultGenreTagNo
    ) {
      return true;
    }
  }

  return false;
};

const handleSave = () => {
  isClickedSubmit.value = true;
  if (!isCheckingProductName.value) {
    progressSave();
  }
};

const progressSave = async () => {
  isClickedSubmit.value = false;
  if (!(await checkProductPermission())) {
    return;
  }
  isGenreTouched.value = true;
  isSubmitted.value = true;
  triggerValidateBasicInformation();
  if (
    !isFieldValid('basicInformationDefault') ||
    (props.processedProductInfo.exposedFields.genre && selectedGenres.value.length === 0) ||
    duplicateProductError.value
  ) {
    if (duplicateProductError.value) {
      setTimeout(() => {
        const errorMessage = generateErrorMsg(
          'studio.prj_prod.this_prod.home_product_setting_val_duplication_msg',
          RuleNames.CHECK_DUPLICATE
        );
        setErrors({ 'basicInformationDefault.productName': errorMessage });
      });
    }
    return;
  }

  isSubmitted.value = true;
  const settingLanguages = languages.value.filter((lang: ProductLanguage) => !lang.default);
  const isEmptySettings = isNotTranslated(settingLanguages);

  if (isEmptySettings) {
    await showAlert({
      content: t('studio.common.popup_case_ai_not_entered')
    });

    onOpenMultilingualSettings();
    return;
  }

  if (isChangeDefaultLanguageOnly.value && isNotChangeSettingLanguages.value) {
    const confirm = await showConfirm({
      content: t('studio.common.popup_case_ai_modified'),
      confirmLabel: t('studio.common.popup_case_save_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });
    if (!confirm) {
      return;
    }
  }

  if (isLiveApply) {
    const selfReview = isSelfReview(props.ratingsProduct);
    updateRevisionProductSettingChanged();
    isHaveBasicInformationChange.value =
      !!props.ratingsProduct?.requestRating &&
      selfReview &&
      (revisionProductSettingChanged.value.isChangedProductName ||
        revisionProductSettingChanged.value.isChangedDeveloper ||
        revisionProductSettingChanged.value.isChangedGenre);

    const applyLiveResult = await showDialog({
      component: shallowRef(ApplyLiveDialog),
      props: {
        content: isHaveBasicInformationChange.value
          ? t('studio.common.popup_case_ii_release_guide')
          : t('studio.common.popup_case_i_release_guide')
      },
      severity: 'info'
    });

    if (!applyLiveResult) {
      return;
    }

    if (isHaveBasicInformationChange.value) {
      const ratingConfirm = await showDialog({
        component: shallowRef(RatingSurveyDialog),
        props: {
          type: SurveyDialogType.REVISION,
          mode: RatingPageMode.EDIT,
          revisionProductSettingChanged
        }
      });

      if (!ratingConfirm) {
        return;
      }
    }
  }

  submit();
};

const getRequestData = () => {
  const requestInfos = (
    isHaveBasicInformationChange.value &&
    (revisionProductSettingChanged.value.isChangedProductName ||
      revisionProductSettingChanged.value.isChangedDeveloper)
      ? props.processedProductInfo.infos
      : languages.value
  ).map((lang: ProductLanguage) => {
    return {
      languageCd: lang.langCode,
      productName: lang.productName,
      developer: lang.developer,
      publisher: lang.publisher
    };
  });

  const isChangedGenre =
    isHaveBasicInformationChange.value && revisionProductSettingChanged.value.isChangedGenre;
  const requestGenreTagNos = isChangedGenre
    ? revisionProductSettingChanged.value.originGenreTagNos
    : genreTagNos.value.map((item: string) => Number(item));
  const requestDefaultGenreTagNo = isChangedGenre
    ? revisionProductSettingChanged.value.originDefaultGenreTag
    : Number(defaultGenreOption.value?.value);

  return {
    productDetailType: detailType.value ?? PRODUCT_DETAIL_TYPE.BASIC,
    demoYn: productType.value === PRODUCT_TYPE.DEMO ? Confirmation.YES : Confirmation.NO,
    infos: requestInfos,
    options: {
      onlineYn: supportOption.value.onlineYn ? Confirmation.YES : Confirmation.NO,
      vrYn: supportOption.value.vrYn ? Confirmation.YES : Confirmation.NO
    },
    genreTagNos: requestGenreTagNos,
    defaultGenreTagNo: requestDefaultGenreTagNo,
    childDemoProductNo:
      productType.value !== PRODUCT_TYPE.DEMO &&
      linkageProducts.value &&
      linkageProducts.value.demo &&
      linkageProducts.value.demo[0]
        ? linkageProducts.value.demo[0].productNo
        : null,
    childDlcProductNos:
      productType.value !== PRODUCT_TYPE.DEMO && linkageProducts.value && linkageProducts.value.dlc
        ? linkageProducts.value.dlc.map((item: ProductParentChild) => item.productNo)
        : [],
    childContentProductNos:
      productType.value !== PRODUCT_TYPE.DEMO &&
      linkageProducts.value &&
      linkageProducts.value.content
        ? linkageProducts.value.content.map((item: ProductParentChild) => item.productNo)
        : [],
    childUtilityProductNos:
      productType.value !== PRODUCT_TYPE.DEMO &&
      linkageProducts.value &&
      linkageProducts.value.utility
        ? linkageProducts.value.utility.map((item: ProductParentChild) => item.productNo)
        : []
  } as ProductSettingRequest;
};

const submit = handleSubmit(async () => {
  const requestData = getRequestData();

  // call api
  if (isLiveApply && isEqual(props.originalProductSetting, requestData)) {
    // no change popup
    await showAlert({
      content: t('studio.common.popup_case_v_release_guide'),
      contentClasses: 'h-96 flex items-center justify-center',
      severity: 'info',
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!w-full !max-w-full'
    });

    return;
  }

  try {
    const productNo = Number(props.productNo);
    const res = await updateProductApi(productNo, requestData);

    if (res) {
      if (res.success) {
        await Promise.all([
          productStore.fetchProductsInfo(props.productNo),
          appStore.fetchProjectProductItems()
        ]);
        const productName = requestData.infos.find(
          (lang: ProductSettingInfo) => lang.languageCd === locale.value
        )?.productName;
        appStore.updateProductFromSelectedProductListHistory(
          productNo,
          productName || '',
          requestData.demoYn,
          requestData.productDetailType
        );
        if (isLiveApply) {
          await showAlert({
            content: t('studio.common.popup_case_iv_release_guide'),
            contentClasses: 'h-96 flex items-center justify-center',
            severity: 'info',
            confirmLabel: t('studio.common.popup_case_cf_btn'),
            confirmClasses: '!w-full !max-w-full'
          });

          reloadNuxtApp();
          return;
        }

        const isChangedRatingInformation = await checkChangedRatingInformation();
        if (isChangedRatingInformation) {
          const confirm = await showConfirm({
            content: t(
              'studio.prj_prod.this_prod.home_product_setting_info_mismatch_new_reg_popup'
            ),
            confirmLabel: t('studio.prj_prod.this_prod.home_product_setting__new_reg_btn'),
            cancelLabel: t('studio.common.popup_case_close_btn'),
            cancelVariant: 'outline'
          });
          if (confirm) {
            redirectTo(PRODUCT_RATING_URL);
            return;
          }

          reloadNuxtApp();
          return;
        }
      }
      showCompleteSaveDialog();
    }
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.INVALID_CHILDREN) {
      await showAlert({
        content: t('studio.prj_prod.this_prod.home_product_setting_linked_product_exist_popup'),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
    } else if (errorCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
      const errorMessage = generateErrorMsg(
        'studio.prj_prod.this_prod.home_product_setting_basic_msg3_duplicate',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ 'basicInformationDefault.productName': errorMessage });
    }
  }
});

watch(productName, (value: string) => {
  const index = languages.value.findIndex((info: ProductLanguage) => info.langCode === selectedGroupInfo.value?.languageCd);
  if (index > -1) {
    languages.value[index].productName = value;
  }
});

watch(developer, (value: string) => {
  const index = languages.value.findIndex((info: ProductLanguage) => info.langCode === selectedGroupInfo.value?.languageCd);
  if (index > -1) {
    languages.value[index].developer = value;
  }
});

watch(publisher, (value: string) => {
  const index = languages.value.findIndex((info: ProductLanguage) => info.langCode === selectedGroupInfo.value?.languageCd);
  if (index > -1) {
    languages.value[index].publisher = value;
  }
});

// TODO v.22 Withhold ability to change Demo -> Basic type after release
// watch(productType, (newValue?: string, oldValue?: string) => {
//   // Screen 17-7-3 - If the product type is changed to DEMO, a confirmation popup is displayed.
//   if (oldValue && isLiveApply && props.productInfo.demoYn === Confirmation.YES && newValue !== PRODUCT_TYPE.DEMO) {
//     progressChangeType(newValue!);
//   }
// });

const updateLinkageProducts = (v: ILinkageProducts) => {
  linkageProducts.value = v;
};

const getProductTypeText = (productType: string) => {
  switch (props.productInfo.productType) {
    case SERVER_PRODUCT_TYPE.CONTENTS:
      return productType === PRODUCT_TYPE.CONTENT
        ? t('studio.prj_prod.this_prod.home_product_setting_prod_type_basic_content')
        : t('studio.prj_prod.this_prod.home_product_setting_prod_type_demo_content');
    case SERVER_PRODUCT_TYPE.UTILITY:
      return productType === PRODUCT_TYPE.UTILITY
        ? t('studio.prj_prod.this_prod.home_product_setting_prod_type_basic_util')
        : t('studio.prj_prod.this_prod.home_product_setting_prod_type_demo_util');
    case SERVER_PRODUCT_TYPE.GAME:
    default:
      return productType === PRODUCT_TYPE.GAME
        ? t('studio.prj_prod.this_prod.home_product_setting_prod_type_basic')
        : t('studio.prj_prod.this_prod.home_product_setting_prod_type_demo');
  }
};

const init = async () => {
  languages.value = cloneDeep(props.processedProductInfo.infos);
  originalLanguages.value = cloneDeep(props.processedProductInfo.infos);
  supportOption.value = {
    onlineYn: props.processedProductInfo.options?.onlineYn === Confirmation.YES,
    vrYn: props.processedProductInfo.options?.vrYn === Confirmation.YES
  };

  productType.value = props.processedProductInfo.productType;

  setValues({
    basicInformationDefault: {
      productName: props.processedProductInfo.defaultBasicInformation.productName,
      developer: props.processedProductInfo.defaultBasicInformation.developer,
      publisher: props.processedProductInfo.defaultBasicInformation.publisher
    },
    detailType: props.processedProductInfo.detailType,
    genreTagNos: props.processedProductInfo.genreTagNos
  });

  linkageProducts.value = {
    demo: props.productInfo?.childDemoProduct
      ? JSON.parse(JSON.stringify([props.productInfo.childDemoProduct]))
      : null,
    dlc: JSON.parse(JSON.stringify(props.productInfo?.childDlcProducts)),
    content: JSON.parse(JSON.stringify(props.productInfo?.childContentProducts)),
    utility: JSON.parse(JSON.stringify(props.productInfo?.childUtilityProducts))
  };
};

init();
</script>
