<template>
  <div class="flex-1">
    <st-box>
      <div class="flex gap-4 items-center">
        <span class="w-12 h-12 rounded-full bg-[#FFC226] shrink-0"></span>
        <span class="text-md leading-lg font-medium text-on-surface-elevation-2">{{
          $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_stt')
        }}</span>
      </div>
      <p class="mt-4 text-sm font-medium leading-md text-on-surface-elevation-3">
        {{ $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_guide') }}
      </p>
      <div class="mt-24 flex flex-col gap-20">
        <product-info-item
          :titleValue="
            $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_prod_name')
          "
          :dataValue="processedProductInfo?.defaultBasicInformation?.productName ?? ''"
        />
        <product-info-item
          :titleValue="
            $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_creator_name')
          "
          :dataValue="processedProductInfo?.defaultBasicInformation?.developer ?? ''"
        />
        <product-info-item
          :titleValue="
            $t(
              'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_distrib_co_name'
            )
          "
          :dataValue="processedProductInfo?.defaultBasicInformation?.publisher ?? ''"
        />
        <product-info-item
          v-if="processedProductInfo.exposedFields.genre"
          :titleValue="
            $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_genre')
          "
          :dataValue="genreText"
        />
      </div>
      <div class="mt-24 pt-24 border-t-1 border-solid border-border flex flex-col gap-20">
        <product-info-item
          v-if="processedProductInfo.exposedFields.productType"
          :titleValue="
            $t('studio.prj_prod.this_prod.home_product_setting_under_review_info_view_prod_type')
          "
          :dataValue="getProductTypeText(processedProductInfo?.productType) ?? ''"
        />
        <product-info-item
          v-if="
            processedProductInfo.exposedFields.supportedFeatures ||
              (productInfo.productDetailType === PRODUCT_DETAIL_TYPE.DLC && productInfo.parentProduct)
          "
          :titleValue="
            $t(
              'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_supported_type'
            )
          "
          :dataValue="supportFeatureText"
        />
        <template v-if="productInfo?.childDemoProduct">
          <st-form-title :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_msg1')" />
          <st-table
            :data="productInfo?.childDemoProduct ? [productInfo.childDemoProduct] : []"
            :columns="columns"
          />
        </template>
        <template
          v-if="
            productInfo.productDetailType !== PRODUCT_DETAIL_TYPE.DLC &&
              productInfo?.childDlcProducts &&
              productInfo?.childDlcProducts.length > 0
          "
        >
          <st-form-title
            class="mt-16"
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_msg2')"
          />
          <st-table
            :data="productInfo?.childDlcProducts ?? productInfo.childDlcProducts"
            :columns="columns"
          />
        </template>
        <!-- <template v-if="productInfo?.childContentProducts">
          <st-form-title
            class="mt-16"
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_msg3')"
          />
          <st-table :data="productInfo?.childContentProducts ?? [productInfo.childContentProducts]" :columns="columns" />
        </template> -->
        <template
          v-if="productInfo?.childUtilityProducts && productInfo?.childUtilityProducts.length > 0"
        >
          <st-form-title
            class="mt-16"
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_msg4')"
          />
          <st-table
            :data="productInfo?.childUtilityProducts ?? productInfo.childUtilityProducts"
            :columns="columns"
          />
        </template>
      </div>
    </st-box>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchProductsInfoApi } from '@/apis/products.api';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import ProductInfoItem from '@/components/product-settings/product-info-item.vue';
import { PRODUCT_DETAIL_TYPE, PRODUCT_TYPE, SERVER_PRODUCT_TYPE } from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import type { Genre, ProcessedProductInfo } from '@/types/product/product-model.type';
import type { ProductParentChild, ProductResponse } from '@/types/product/product-response.type';
import type { Column } from '@/types/table.type';
import { getProductReleaseStatusLabel } from '@/utils/product.util';

const props = defineProps<{
  productInfo: ProductResponse;
  processedProductInfo: ProcessedProductInfo;
}>();

const { t } = useI18n();
const parentProduct = ref<ProductResponse>();

const columns = ref<Column[]>([
  {
    title: t('studio.prj_prod.this_prod.home_product_setting_prod_id_header1'),
    dataIndex: 'productNo',
    width: '8.8rem',
    align: 'left'
  },
  {
    title: t('studio.prj_prod.this_prod.home_product_setting_prod_name_header2'),
    dataIndex: 'productName',
    width: 'auto',
    align: 'left'
  },
  {
    title: t('studio.prj_prod.this_prod.home_product_setting_prod_status_header3'),
    dataIndex: 'releaseStatus',
    width: '10rem',
    align: 'left',
    render: (row: ProductParentChild) =>
      t(
        getProductReleaseStatusLabel(
          row.planStatus,
          row.saleStatus,
          row.verifyStatus,
          row.displayYn,
          row.deleteRequestStatus
        )
      )
  }
]);

const genreText = computed(() => {
  if (!props.productInfo?.genres) {
    return '';
  }

  const sortedGenres = [...props.productInfo.genres].sort((a: Genre, b: Genre) => {
    if (a.defaultGenreYn === Confirmation.YES && b.defaultGenreYn !== Confirmation.YES) {
      return -1;
    }

    if (a.defaultGenreYn !== Confirmation.YES && b.defaultGenreYn === Confirmation.YES) {
      return 1;
    }

    return 0;
  });

  const genreTexts = sortedGenres.map((genre: Genre) => {
    if (genre.defaultGenreYn === Confirmation.YES) {
      return `${genre.tagName}(${t(
        'studio.product_setting.under_review_info_genre_representative'
      )})`;
    }
    return genre.tagName;
  });

  return genreTexts.join(', ');
});

const supportFeatureText = computed(() => {
  let onlineYn = props.productInfo?.options?.onlineYn;
  let vrYn = props.productInfo?.options?.vrYn;

  if (
    props.productInfo.productDetailType === PRODUCT_DETAIL_TYPE.DLC &&
    props.productInfo.parentProduct &&
    parentProduct.value
  ) {
    onlineYn = parentProduct.value.options?.onlineYn;
    vrYn = parentProduct.value.options?.vrYn;
  }

  if (onlineYn === Confirmation.YES && vrYn === Confirmation.YES) {
    return t(
      'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_supported_type2_onl_vr'
    );
  } else if (onlineYn === Confirmation.YES) {
    return t(
      'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_supported_type1_onl'
    );
  } else if (vrYn === Confirmation.YES) {
    return 'VR';
  }
  return t(
    'studio.prj_prod.this_prod.home_product_setting_under_review_info_view_supported_type3_none'
  );
});

const getProductTypeText = (productType: string) => {
  switch (props.productInfo.productType) {
    case SERVER_PRODUCT_TYPE.CONTENTS:
      return productType === PRODUCT_TYPE.CONTENT
        ? t('studio.prj_prod.this_prod.home_product_setting_prod_type_basic_content')
        : t('studio.prj_prod.this_prod.home_product_setting_prod_type_demo_content');
    case SERVER_PRODUCT_TYPE.UTILITY:
      return productType === PRODUCT_TYPE.UTILITY
        ? t('studio.prj_prod.this_prod.home_product_setting_prod_type_basic_util')
        : t('studio.prj_prod.this_prod.home_product_setting_prod_type_demo_util');
    case SERVER_PRODUCT_TYPE.GAME:
    default:
      return productType === PRODUCT_TYPE.GAME
        ? t('studio.prj_prod.this_prod.home_product_setting_prod_type_basic')
        : t('studio.prj_prod.this_prod.home_product_setting_prod_type_demo');
  }
};

const init = async () => {
  if (
    props.productInfo.productDetailType === PRODUCT_DETAIL_TYPE.DLC &&
    props.productInfo.parentProduct
  ) {
    parentProduct.value = await fetchProductsInfoApi(
      props.productInfo.parentProduct.productNo.toString()
    );
  }
};

init();
</script>
