import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { InGamePurchaseResponse } from '@/types/in-game/in-game-response.type';
import { generateHeader } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+In-game+setting+purchase+%7C+GET+In+Game+In+Game+Purchase+By+Game
export const fetchInGamePurchase = async (
  gameId: string
): Promise<InGamePurchaseResponse | undefined> => {
  const { data } = await useRequest<InGamePurchaseResponse>(
    `${apiUrl}/in-game/purchase/${gameId}`,
    {
      headers: { ...generateHeader('X-Lang', 'X-Nation') },
      method: ApiMethod.Get
    }
  );

  return data;
};
