i
<template>
  <div>
    <s-dialog :open="true" size="md" to="stds-dialog-create-new-product">
      <s-dialog-overlay />
      <s-dialog-panel>
        <st-dialog-header :isCloseButtonShown="false" @clickClose="onClose" />
        <s-dialog-content class="!pb-24">
          <s-dialog-content-body>
            <div class="pt-24">
              <p class="text-xl leading-lg font-bold text-on-surface-elevation-1 text-center mb-24">
                {{ $t(getTitle(linkType)) }}
              </p>
              <input-text
                name="productName"
                maxLength="60"
                :placeholder="
                  $t('studio.prj_prod.this_prod.home_product_setting_new_add_pop_place_holder')
                "
                :rules="{
                  required: $t('studio.prj_prod.this_prod.home_product_setting_val_required_n'),
                  max_length: {
                    length: 60,
                    message: $t('studio.common.def_key.exceed', { length: 60 })
                  },
                  regex: {
                    regex: REGEX_PRODUCT_NAME,
                    message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
                  }
                }"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="w-full" variant="outline" @click="onClose">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button class="w-full" @click="confirm">
            {{ $t('studio.prj_prod.this_prod.home_product_setting_add_register_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-create-new-product" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  checkProductInProjectNameApi,
  createProductInProjectApi
} from '@/apis/project-product.api';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import InputText from '@/components/validation/input-text.vue';
import { showAlert } from '@/composables/useDialog';
import { ALL } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { LINKAGE_PRODUCT_TYPE } from '@/constants/products.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { RuleNames } from '@/enums/validation.enum';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { ProductType } from '@/types/product/product-model.type';
import type { ProductParentChild } from '@/types/product/product-response.type';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  linkType: keyof typeof LINKAGE_PRODUCT_TYPE;
  type: ProductType;
  productName: string;
}>();

const { t } = useI18n();
const { linkType, type, productName } = toRefs(props);
const productStore = useProductStore();

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const getTitle = (type: string) => {
  switch (type) {
    case LINKAGE_PRODUCT_TYPE.DEMO:
      return 'studio.prj_prod.this_prod.home_product_setting_demo_add_pop_msg';
    case LINKAGE_PRODUCT_TYPE.DLC:
      return 'studio.prj_prod.this_prod.home_product_setting_dlc_add_pop_msg';
    case LINKAGE_PRODUCT_TYPE.CONTENTS:
      return 'studio.prj_prod.this_prod.home_product_setting_content_add_pop_msg';
    case LINKAGE_PRODUCT_TYPE.UTILITY:
      return 'studio.prj_prod.this_prod.home_product_setting_utility_add_pop_msg';
    default:
      return '-';
  }
};

const emit = defineEmits<{
  close: [product?: ProductParentChild];
}>();

const { handleSubmit, setErrors } = useForm<{ productName: string }>({
  initialValues: {
    productName:
      linkType.value === LINKAGE_PRODUCT_TYPE.DEMO
        ? `${productName.value} DEMO`
        : `${productName.value}:`
  }
});

const confirm = handleSubmit(async (value: { productName: string }) => {
  const productType =
    linkType.value === LINKAGE_PRODUCT_TYPE.DEMO || linkType.value === LINKAGE_PRODUCT_TYPE.DLC
      ? type.value
      : (linkType.value as ProductType);
  try {
    const productNameCheck = await checkProductInProjectNameApi({
      groupId: selectedGroupId.value,
      productName: value.productName,
      languageCd: ALL
    });
    if (!productNameCheck?.success) {
      return;
    }

    const res = await createProductInProjectApi({
      productName: value.productName,
      productType,
      projectId: productStore.product?.projectId ?? ''
    });

    if (res) {
      const result = {
        productNo: res.productNo,
        productName: value.productName,
        productType
      };

      emit('close', result);
    } else {
      showAlert({
        content: t(COMMON_ERROR_MESSAGE_KEY),
        severity: 'info',
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!w-full !max-w-full'
      });
    }
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
      const errorMessage = generateErrorMsg(
        'studio.prj_prod.this_prod.home_product_setting_val_duplication_msg',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ productName: errorMessage });
    }
  }
});

const onClose = () => {
  emit('close');
};
</script>
