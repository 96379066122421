<template>
  <template v-if="checkShowLinkageProducts()">
    <st-form-title
      :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_prod_link')"
      class="mt-32"
    />
    <p class="text-xs leading-xs text-placeholder font-regular mt-4">
      {{ $t('studio.prj_prod.this_prod.home_product_setting_prod_link_guide') }}
    </p>
  </template>
  <select-add-product
    v-if="exposedLinkedProducts.demo"
    :label="$t('studio.prj_prod.this_prod.home_product_setting_msg1')"
    :linkType="LINKAGE_PRODUCT_TYPE.DEMO"
    :type="serverProductType"
    :addedData="linkageProducts?.demo"
    :data="demoProducts.list"
    :totalPages="demoProducts.totalPages"
    :isLoadingWhenFocus="demoProducts.isLoading"
    :productNo="productNo"
    :productName="productName"
    :groupId="groupId"
    :projectId="projectId"
    :excludeProducts="linkageProducts?.dlc"
    isSingle
    @updateSelected="onUpdateSelectedProducts($event, LINKAGE_PRODUCT_TYPE.DEMO)"
    @search="getProducts($event, LINKAGE_PRODUCT_TYPE.DEMO)"
    @createNewProduct="onCreateNewProduct($event, LINKAGE_PRODUCT_TYPE.DEMO)"
    @loadMore="onLoadMoreProducts($event, LINKAGE_PRODUCT_TYPE.DEMO)"
    @focus="getProducts($event, LINKAGE_PRODUCT_TYPE.DEMO)"
  />
  <select-add-product
    v-if="exposedLinkedProducts.dlc"
    :label="$t('studio.prj_prod.this_prod.home_product_setting_msg2')"
    :linkType="LINKAGE_PRODUCT_TYPE.DLC"
    :type="serverProductType"
    :data="dlcProducts.list"
    :addedData="linkageProducts?.dlc"
    :totalPages="dlcProducts.totalPages"
    :productNo="productNo"
    :productName="productName"
    :groupId="groupId"
    :projectId="projectId"
    :isLoadingWhenFocus="dlcProducts.isLoading"
    :excludeProducts="linkageProducts?.demo"
    @updateSelected="onUpdateSelectedProducts($event, LINKAGE_PRODUCT_TYPE.DLC)"
    @search="getProducts($event, LINKAGE_PRODUCT_TYPE.DLC)"
    @createNewProduct="onCreateNewProduct($event, LINKAGE_PRODUCT_TYPE.DLC)"
    @loadMore="onLoadMoreProducts($event, LINKAGE_PRODUCT_TYPE.DLC)"
    @focus="getProducts($event, LINKAGE_PRODUCT_TYPE.DLC)"
  />
  <!-- <select-add-product
    v-if="exposedLinkedProducts.content"
    :label="$t('studio.prj_prod.this_prod.home_product_setting_msg3')"
    :linkType="LINKAGE_PRODUCT_TYPE.CONTENTS"
    :type="serverProductType"
    :data="contentProducts.list"
    :addedData="linkageProducts?.content"
    :totalPages="contentProducts.totalPages"
    :productNo="productNo"
    :productName="productName"
    :groupId="groupId"
    :projectId="projectId"
    :isLoadingWhenFocus="contentProducts.isLoading"
    @updateSelected="onUpdateSelectedProducts($event, LINKAGE_PRODUCT_TYPE.CONTENTS)"
    @search="getProducts($event, LINKAGE_PRODUCT_TYPE.CONTENTS)"
    @createNewProduct="onCreateNewProduct($event, LINKAGE_PRODUCT_TYPE.CONTENTS)"
    @loadMore="onLoadMoreProducts($event, LINKAGE_PRODUCT_TYPE.CONTENTS)"
    @focus="getProducts($event, LINKAGE_PRODUCT_TYPE.CONTENTS)"
  /> -->
  <select-add-product
    v-if="exposedLinkedProducts.utility"
    :label="$t('studio.prj_prod.this_prod.home_product_setting_msg4')"
    :linkType="LINKAGE_PRODUCT_TYPE.UTILITY"
    :type="serverProductType"
    :data="utilityProducts.list"
    :addedData="linkageProducts?.utility"
    :totalPages="utilityProducts.totalPages"
    :productNo="productNo"
    :productName="productName"
    :groupId="groupId"
    :projectId="projectId"
    :isLoadingWhenFocus="utilityProducts.isLoading"
    @updateSelected="onUpdateSelectedProducts($event, LINKAGE_PRODUCT_TYPE.UTILITY)"
    @search="getProducts($event, LINKAGE_PRODUCT_TYPE.UTILITY)"
    @createNewProduct="onCreateNewProduct($event, LINKAGE_PRODUCT_TYPE.UTILITY)"
    @loadMore="onLoadMoreProducts($event, LINKAGE_PRODUCT_TYPE.UTILITY)"
    @focus="getProducts($event, LINKAGE_PRODUCT_TYPE.UTILITY)"
  />
</template>
<script setup lang="ts">
import { ref, toRefs } from 'vue';

import { searchProductsApi, searchProductsDemoSelectApi } from '@/apis/products.api';
import StFormTitle from '@/components/common/st-form-title.vue';
import SelectAddProduct from '@/components/product-settings/select-add-product.vue';
import { SORT_DIRECTION } from '@/constants/common.const';
import { LINKAGE_PRODUCT_TYPE } from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import { VerifyStatus } from '@/enums/rating.enum';
import type { SearchParams } from '@/types/common/common.type';
import type {
  ExposedLinkingFields,
  LinkageProducts,
  ProductList,
  ProductType
} from '@/types/product/product-model.type';
import type { ProductParentChild, ProductSearchItem } from '@/types/product/product-response.type';

const props = defineProps<{
  linkageProducts?: LinkageProducts;
  groupId: string;
  projectId: string;
  productNo: string;
  productName: string;
  serverProductType: string;
  parentProductType?: ProductType;
  exposedLinkedProducts: ExposedLinkingFields;
}>();

const emits = defineEmits<{
  updateLinkageProducts: [v: LinkageProducts];
}>();
const { linkageProducts } = toRefs(props);

const demoProducts = ref<ProductList>({
  list: [],
  totalPages: 0,
  isLoading: false
});
const dlcProducts = ref<ProductList>({
  list: [],
  totalPages: 0,
  isLoading: false
});
const contentProducts = ref<ProductList>({
  list: [],
  totalPages: 0,
  isLoading: false
});
const utilityProducts = ref<ProductList>({
  list: [],
  totalPages: 0,
  isLoading: false
});

const newCreatedDemoProduct = ref<ProductParentChild[]>([]);
const newCreatedDLCProduct = ref<ProductParentChild[]>([]);
const newCreatedContentsProduct = ref<ProductParentChild[]>([]);
const newCreatedUtilityProduct = ref<ProductParentChild[]>([]);

const onSearchProducts = async (params: SearchParams, type: string) => {
  const res =
    type === LINKAGE_PRODUCT_TYPE.DEMO
      ? await searchProductsDemoSelectApi(
        {
          groupId: props.groupId,
          projectId: props.projectId,
          productType: props.serverProductType,
          q: params.q,
          direction: SORT_DIRECTION.DESC,
          page: params.page,
          size: params.size
        },
        false
      )
      : await searchProductsApi(
        {
          groupId: props.groupId,
          projectId: props.projectId,
          productType: type === LINKAGE_PRODUCT_TYPE.DLC ? props.serverProductType : type,
          parentYn: Confirmation.NO,
          childYn: Confirmation.NO,
          q: params.q,
          direction: SORT_DIRECTION.DESC,
          page: params.page,
          size: params.size,
          demoYn: Confirmation.NO,
          sort: 'PRODUCT_NO',
          verifyStatus: type === LINKAGE_PRODUCT_TYPE.DLC ? VerifyStatus.NONE : undefined
        },
        false
      );

  if (res) {
    const list: ProductParentChild[] = res.contents.map((product: ProductSearchItem) => {
      return {
        productNo: product.productNo,
        productName: product.productName,
        productType: product.productType,
        productDetailType: product.productDetailType,
        planStatus: product.planStatus,
        saleStatus: product.saleStatus,
        verifyStatus: product.verifyStatus,
        displayYn: product.displayYn,
        deleteRequestStatus: product.deleteRequestStatus,
        gameId: product.gameId
      } as ProductParentChild;
    });
    return {
      list,
      totalPages: res.totalPages
    };
  }

  return { list: [], totalPages: 0 };
};

const getProducts = async (v: SearchParams, type: string) => {
  switch (type) {
    case LINKAGE_PRODUCT_TYPE.DEMO:
      demoProducts.value.isLoading = true;
      break;
    case LINKAGE_PRODUCT_TYPE.DLC:
      dlcProducts.value.isLoading = true;
      break;
    case LINKAGE_PRODUCT_TYPE.CONTENTS:
      contentProducts.value.isLoading = true;
      break;
    case LINKAGE_PRODUCT_TYPE.UTILITY:
      utilityProducts.value.isLoading = true;
      break;
    default:
      break;
  }

  const data: ProductList = await onSearchProducts(v, type);

  switch (type) {
    case LINKAGE_PRODUCT_TYPE.DEMO: {
      const newData = data.list.filter((item: ProductParentChild) =>
        newCreatedDemoProduct.value.every(
          (newItem: ProductParentChild) => newItem.productNo !== item.productNo
        )
      );
      demoProducts.value.list = [...newCreatedDemoProduct.value, ...newData];
      demoProducts.value.totalPages = data.totalPages;
      demoProducts.value.isLoading = false;
      break;
    }
    case LINKAGE_PRODUCT_TYPE.DLC: {
      const newData = data.list.filter((item: ProductParentChild) =>
        newCreatedDLCProduct.value.every(
          (newItem: ProductParentChild) => newItem.productNo !== item.productNo
        )
      );
      dlcProducts.value.list = [...newCreatedDLCProduct.value, ...newData];
      dlcProducts.value.totalPages = data.totalPages;
      dlcProducts.value.isLoading = false;
      break;
    }
    case LINKAGE_PRODUCT_TYPE.CONTENTS: {
      const newData = data.list.filter((item: ProductParentChild) =>
        newCreatedContentsProduct.value.every(
          (newItem: ProductParentChild) => newItem.productNo !== item.productNo
        )
      );
      contentProducts.value.list = [...newCreatedContentsProduct.value, ...newData];
      contentProducts.value.totalPages = data.totalPages;
      contentProducts.value.isLoading = false;
      break;
    }
    case LINKAGE_PRODUCT_TYPE.UTILITY: {
      const newData = data.list.filter((item: ProductParentChild) =>
        newCreatedUtilityProduct.value.every(
          (newItem: ProductParentChild) => newItem.productNo !== item.productNo
        )
      );
      utilityProducts.value.list = [...newCreatedUtilityProduct.value, ...newData];
      utilityProducts.value.totalPages = data.totalPages;
      utilityProducts.value.isLoading = false;
      break;
    }
    default:
      break;
  }
};

const onLoadMoreProducts = async (v: SearchParams, type: string) => {
  const moreData: ProductList = await onSearchProducts(v, type);

  switch (type) {
    case LINKAGE_PRODUCT_TYPE.DEMO: {
      demoProducts.value.list = [...demoProducts.value.list, ...moreData.list];
      demoProducts.value.totalPages = moreData.totalPages;
      break;
    }
    case LINKAGE_PRODUCT_TYPE.DLC:
      dlcProducts.value.list = [...dlcProducts.value.list, ...moreData.list];
      dlcProducts.value.totalPages = moreData.totalPages;
      break;
    case LINKAGE_PRODUCT_TYPE.CONTENTS:
      contentProducts.value.list = [...contentProducts.value.list, ...moreData.list];
      contentProducts.value.totalPages = moreData.totalPages;
      break;
    case LINKAGE_PRODUCT_TYPE.UTILITY:
      utilityProducts.value.list = [...utilityProducts.value.list, ...moreData.list];
      utilityProducts.value.totalPages = moreData.totalPages;
      break;
    default:
      break;
  }
};

const onUpdateSelectedProducts = (v: ProductParentChild[], type: string) => {
  const newValue: LinkageProducts = { ...linkageProducts.value };
  switch (type) {
    case LINKAGE_PRODUCT_TYPE.DEMO:
      newValue.demo = [...v];
      break;
    case LINKAGE_PRODUCT_TYPE.DLC:
      newValue.dlc = [...v];
      break;
    case LINKAGE_PRODUCT_TYPE.CONTENTS:
      newValue.content = [...v];
      break;
    case LINKAGE_PRODUCT_TYPE.UTILITY:
      newValue.utility = [...v];
      break;
    default:
      break;
  }

  emits('updateLinkageProducts', newValue);
};

const onCreateNewProduct = (v: ProductParentChild, type: string) => {
  if (!v) {
    return;
  }
  switch (type) {
    case LINKAGE_PRODUCT_TYPE.DEMO:
      newCreatedDemoProduct.value.unshift(v);
      demoProducts.value.list.unshift(v);
      break;
    case LINKAGE_PRODUCT_TYPE.DLC:
      newCreatedDLCProduct.value.unshift(v);
      dlcProducts.value.list.unshift(v);
      break;
    case LINKAGE_PRODUCT_TYPE.CONTENTS:
      newCreatedContentsProduct.value.unshift(v);
      contentProducts.value.list.unshift(v);
      break;
    case LINKAGE_PRODUCT_TYPE.UTILITY:
      newCreatedUtilityProduct.value.unshift(v);
      utilityProducts.value.list.unshift(v);
      break;
    default:
      break;
  }
};

const checkShowLinkageProducts = () =>
  Object.values(props.exposedLinkedProducts).some((v: boolean) => v);
</script>
