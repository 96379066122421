<template>
  <template v-if="isPageReady">
    <edit-product-settings
      v-if="editMode"
      :productInfo="productInfo!"
      :userInfo="userInfo"
      :processedProductInfo="processedProductInfo"
      :genreOptions="genreOptions"
      :defaultGenreOption="defaultGenreOption"
      :defaultLangCode="defaultLangCode"
      :groupId="groupId"
      :projectId="projectId"
      :productNo="productNo"
      :originalProductSetting="originalProductSetting!"
      :isRatingReview="isRatingReview"
      :ratingsProduct="ratingsProduct"
    />
    <view-product-settings
      v-else
      :productInfo="productInfo!"
      :processedProductInfo="processedProductInfo"
    />
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import { fetchRatingsProductsApi } from '@/apis/rating.api';
import { fetchTagsApi } from '@/apis/tag.api';
import EditProductSettings from '@/components/product-settings/edit-product-settings.vue';
import ViewProductSettings from '@/components/product-settings/view-product-settings.vue';
import { DEFAULT_LANG_CODE, TRANSLATE_LANGUAGES } from '@/constants/common.const';
import { PRODUCT_TITLE } from '@/constants/page.const';
import { ROLE_ID } from '@/constants/permission.const';
import {
  PRODUCT_FEAT_NAME,
  PRODUCT_TYPE,
  PRODUCT_TYPE_DETAIL,
  SERVER_PRODUCT_TYPE,
  VERIFY_STATUS
} from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import { TagType } from '@/enums/tag.enum';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { CommonOption, LanguageModel } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  Genre,
  ProcessedProductInfo,
  ProductLanguage,
  ProductSettingInfo
} from '@/types/product/product-model.type';
import type { ProductSettingRequest } from '@/types/product/product-request.type';
import type { ProductParentChild } from '@/types/product/product-response.type';
import type { RatingProductResponse } from '@/types/rating/rating.response.type';
import type { Tag } from '@/types/tags/tags-response.type';
import { showConfirmLeaveDialog } from '@/utils/common.util';
import { getProductReleaseStatusLabel } from '@/utils/product.util';
import { isVerifyingStatus } from '@/utils/rating.util';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  roleAuthorityId: ROLE_ID.PRODUCT_SETTING,
  productFeat: PRODUCT_FEAT_NAME.SETTINGS,
  pageTitle: PRODUCT_TITLE.SETTINGS
});

const DEFAULT_EXPOSED_FIELDS = {
  basicInformation: false,
  genre: false,
  productType: false,
  detailType: false,
  supportedFeatures: false,
  topProduct: false,
  linking: {
    demo: false,
    dlc: false,
    content: false,
    utility: false
  }
};

const productStore = useProductStore();
const { product: productInfo, currentProductDetailType } = storeToRefs(productStore);

const userStore = useUserStore();
const { userInfo } = storeToRefs(userStore);
const defaultLangCode = userStore.selectedGroupInfo?.languageCd ?? DEFAULT_LANG_CODE;

const route = useRoute();
const groupId = route.params.groupId as string;
const projectId = route.params.projectId as string;
const productNo = route.params.productId as string;

const isPageReady = ref(false);
const ratingsProduct = ref<RatingProductResponse>();
const isRatingReview = ref(false);
const editMode = ref(true);
const genreOptions = ref<FormOption[]>([]);
const defaultGenreNo = ref<number | undefined>(
  productInfo.value?.genres.find((genre: Genre) => genre.defaultGenreYn === Confirmation.YES)?.tagNo
);
const defaultGenreOption = ref<CommonOption | undefined>(undefined);
const originalProductSetting = ref<ProductSettingRequest>();
const processedProductInfo = ref<ProcessedProductInfo>({
  infos: [] as ProductLanguage[],
  productTypeOptions: [] as string[],
  defaultBasicInformation: {
    productName: '',
    developer: '',
    publisher: ''
  },
  disabledProductType: false,
  detailType: '',
  genreTagNos: [] as string[],
  productType: '',
  options: {
    onlineYn: Confirmation.NO,
    vrYn: Confirmation.NO
  },
  defaultLanguageIndex: 0,
  serverProductType: '',
  exposedFields: {
    basicInformation: true,
    genre: true,
    productType: true,
    detailType: true,
    supportedFeatures: true,
    topProduct: true,
    linking: {
      demo: true,
      dlc: true,
      content: true,
      utility: true
    }
  }
});

const languageMapper = () => {
  if (productInfo.value) {
    return TRANSLATE_LANGUAGES.map((lang: LanguageModel) => {
      const info = productInfo.value?.infos.find(
        (info: ProductSettingInfo) => info.languageCd === lang.langCode
      );
      return {
        productName: info?.productName ?? '',
        developer: info?.developer ?? '',
        publisher: info?.publisher ?? '',
        langCode: lang.langCode,
        default: lang.langCode === defaultLangCode
      };
    });
  }

  return [];
};
const getProductTypeOptions = () => {
  switch (productInfo.value?.productType) {
    case SERVER_PRODUCT_TYPE.CONTENTS:
      return [PRODUCT_TYPE.CONTENT, PRODUCT_TYPE.DEMO];
    case SERVER_PRODUCT_TYPE.UTILITY:
      return [PRODUCT_TYPE.UTILITY, PRODUCT_TYPE.DEMO];
    case SERVER_PRODUCT_TYPE.GAME:
    default:
      return [PRODUCT_TYPE.GAME, PRODUCT_TYPE.DEMO];
  }
};

const getExposedFields = () => {
  switch (currentProductDetailType.value) {
    case PRODUCT_TYPE_DETAIL.GAME_BASIC:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        genre: true,
        productType: true,
        supportedFeatures: true,
        linking: {
          demo: true,
          dlc: true,
          content: true,
          utility: true
        }
      };
    case PRODUCT_TYPE_DETAIL.GAME_DEMO:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        genre: true,
        productType: true,
        supportedFeatures: true,
        topProduct: !!productInfo.value?.parentProduct,
        linking: {
          demo: true,
          dlc: true,
          content: true,
          utility: true
        }
      };
    case PRODUCT_TYPE_DETAIL.GAME_DLC:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        genre: true,
        topProduct: !!productInfo.value?.parentProduct
      };
    case PRODUCT_TYPE_DETAIL.UTILITY_BASIC:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        productType: true,
        topProduct: !!productInfo.value?.parentProduct,
        linking: {
          demo: true,
          dlc: true,
          content: false,
          utility: false
        }
      };
    case PRODUCT_TYPE_DETAIL.UTILITY_DEMO:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        productType: true,
        topProduct: !!productInfo.value?.parentProduct,
        linking: {
          demo: true,
          dlc: true,
          content: false,
          utility: false
        }
      };
    case PRODUCT_TYPE_DETAIL.UTILITY_DLC:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        topProduct: !!productInfo.value?.parentProduct
      };
    case PRODUCT_TYPE_DETAIL.CONTENT_BASIC:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        productType: true,
        detailType: true,
        topProduct: !!productInfo.value?.parentProduct,
        linking: {
          demo: true,
          dlc: false,
          content: false,
          utility: false
        }
      };
    case PRODUCT_TYPE_DETAIL.CONTENT_DEMO:
      return {
        ...DEFAULT_EXPOSED_FIELDS,
        basicInformation: true,
        productType: true,
        detailType: true,
        topProduct: !!productInfo.value?.parentProduct
      };
    default:
      return DEFAULT_EXPOSED_FIELDS;
  }
};

const isReleased =
  getProductReleaseStatusLabel(
    productInfo.value?.planStatus,
    productInfo.value?.saleStatus,
    productInfo.value?.verifyStatus,
    productInfo.value?.displayYn,
    productInfo.value?.deleteRequestStatus
  ) !== '-';
const exposedFields = getExposedFields();

const checkDisabledStatusProductType = () => {
  if (!exposedFields?.productType) {
    return true;
  }

  if (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.CONTENT_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_BASIC
  ) {
    if (isReleased) {
      return true;
    }
  }

  if (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.CONTENT_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_BASIC
  ) {
    if (productInfo.value?.parentProduct) {
      return true;
    }
  }
  return false;
};

const getSelectedProductType = (options: string[]) => {
  return options[productInfo.value?.demoYn === Confirmation.YES ? 1 : 0];
};

const getSelectedDetailType = () => {
  return productInfo.value?.productDetailType;
};

const setupData = () => {
  if (productInfo.value) {
    if (
      productInfo.value.verifyStatus === VERIFY_STATUS.START ||
      productInfo.value.verifyStatus === VERIFY_STATUS.REQUEST
    ) {
      editMode.value = false;
    }

    const infos = languageMapper();
    const defaultLanguageIndex =
      infos.findIndex((language: ProductLanguage) => language.default) ?? 0;
    const productTypeOptions = getProductTypeOptions();

    const defaultBasicInformation = {
      productName: infos[defaultLanguageIndex]?.productName,
      developer: infos[defaultLanguageIndex]?.developer,
      publisher: infos[defaultLanguageIndex]?.publisher
    };

    originalProductSetting.value = {
      productDetailType: productInfo.value.productDetailType,
      demoYn: productInfo.value.demoYn,
      infos: productInfo.value.infos,
      options: productInfo.value.options,
      genreTagNos: productInfo.value.genres.map((genre: Tag) => genre.tagNo),
      defaultGenreTagNo: productInfo.value?.genres.find(
        (genre: Genre) => genre.defaultGenreYn === Confirmation.YES
      )?.tagNo,
      childDemoProductNo: productInfo.value.childDemoProduct?.productNo || null,
      childDlcProductNos: productInfo.value.childDlcProducts.map(
        (product: ProductParentChild) => product.productNo
      ),
      childContentProductNos: productInfo.value.childContentProducts.map(
        (product: ProductParentChild) => product.productNo
      ),
      childUtilityProductNos: productInfo.value.childUtilityProducts.map(
        (product: ProductParentChild) => product.productNo
      )
    };

    processedProductInfo.value = {
      infos,
      productTypeOptions,
      defaultBasicInformation,
      disabledProductType: checkDisabledStatusProductType(),
      detailType: getSelectedDetailType(),
      genreTagNos: productInfo.value.genres.map((genre: Genre) => genre.tagNo.toString()),
      productType: getSelectedProductType(productTypeOptions),
      options: productInfo.value.options,
      defaultLanguageIndex,
      serverProductType: productInfo.value.productType,
      exposedFields
    };
  }
};

const fetchGenres = async () => {
  const tags = (await fetchTagsApi({ tagType: TagType.Genre })) || [];
  genreOptions.value = tags.map((tag: Tag) => {
    return {
      label: tag.tagName,
      value: tag.tagNo.toString()
    };
  });

  if (defaultGenreNo.value) {
    defaultGenreOption.value = genreOptions.value.find(
      (option: FormOption) => option.value === defaultGenreNo.value!.toString()
    );
  }
};

const fetchRating = async () => {
  try {
    const ratingsProductsRes = await fetchRatingsProductsApi(productNo);
    if (ratingsProductsRes) {
      if (
        ratingsProductsRes.requestRating &&
        Object.keys(ratingsProductsRes.requestRating).length !== 0
      ) {
        isRatingReview.value = isVerifyingStatus(
          ratingsProductsRes.requestRating?.status,
          ratingsProductsRes.requestRating?.verifyInfo?.verifyStatus
        );
      }

      ratingsProduct.value = ratingsProductsRes;
    }
  } catch (error) {}
};

const init = async () => {
  await Promise.all([productStore.fetchProductsInfo(productNo), fetchGenres(), fetchRating()]);
  setupData();
  isPageReady.value = true;
};

init();

onBeforeRouteLeave(async () => {
  return await showConfirmLeaveDialog();
});
</script>
